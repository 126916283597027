export { matchers } from './client-matchers.js';

export const nodes = [() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42')];

export const server_loads = [0,8];

export const dictionary = {
	"/": [9],
	"/(admin)/admin/users/create": [~10],
	"/(auth)/auth/login": [~26,[6]],
	"/(auth)/auth/set-password/[setPasswordToken]": [~27,[6]],
	"/cutting-size-browser": [~39],
	"/(apex)/engineering": [~11,[2]],
	"/(apex)/engineering/parts": [~12,[2]],
	"/(apex)/engineering/parts/[partId]": [~13,[2,3]],
	"/(apex)/engineering/parts/[partId]/revisions": [~14,[2,3]],
	"/(apex)/engineering/parts/[partId]/routings": [~15,[2,3]],
	"/(apex)/engineering/parts/[partId]/routings/[routingId]": [~16,[2,3]],
	"/(apex)/engineering/parts/[partId]/routings/[routingId]/[operationNumber]": [~17,[2,3]],
	"/part-dependency-browser": [40],
	"/playground/using": [41],
	"/(apex)/production": [18,[4]],
	"/(apex)/production/jobs": [~19,[4]],
	"/(apex)/production/operators": [~20,[4]],
	"/(apex)/production/operators/[username]": [~21,[4]],
	"/(apex)/sales": [22,[5]],
	"/(apex)/sales/quotes": [~23,[5]],
	"/(apex)/sales/quotes/[quoteId]": [~24,[5]],
	"/test/modifyJob": [42],
	"/(workstation)/workstations": [~28,[7]],
	"/(workstation)/workstations/[workstationId]": [~29,[7,8]],
	"/(workstation)/workstations/[workstationId]/manage/jobs": [~30,[7,8]],
	"/(workstation)/workstations/[workstationId]/manage/stock": [~31,[7,8]],
	"/(workstation)/workstations/[workstationId]/pending/book-in": [~32,[7,8]],
	"/(workstation)/workstations/[workstationId]/pending/kit": [~33,[7,8]],
	"/(workstation)/workstations/[workstationId]/pending/operations": [~34,[7,8]],
	"/(workstation)/workstations/[workstationId]/pending/orders": [~35,[7,8]],
	"/(workstation)/workstations/[workstationId]/view/jobs": [~36,[7,8]],
	"/(workstation)/workstations/[workstationId]/view/sections": [~37,[7,8]],
	"/(workstation)/workstations/[workstationId]/view/sections/tv": [~38],
	"/(apex)/xxx": [~25]
};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};